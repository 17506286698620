import React from 'react';
import { LuPhone } from "react-icons/lu";
import DownloadButtons from '../buttons/downloadButton';

function Footer() {
  return (
    <div className='flex flex-col w-full h-[400px] mt-16 items-center'>
      {/* Gradient bar */}
      <div className='w-full h-1 bg-[#002252] border-2 border-[#002252]'></div>
      <div className='flex flex-col w-11/12 lg:justify-between lg:flex-row'>
        <div className='flex flex-col justify-center h-full gap-4'>
          <div className='text-2xl font-bold text-[#002252] sm:text-2xl lg:text-4xl'>PRO<span className='text-[#FCCF00]'>MENDER</span></div>
          <DownloadButtons/>
        </div>
        <div className='flex flex-col items-center justify-center h-full lg:pl-5'>
          <div className='flex flex-row md:justify-between lg:justify-center lg:items-center'>
            <div className='flex flex-col gap-2 mr-10 text-base lg:gap-0 lg:text-base'>
              <a href="#footer-section1" className="text-current no-underline hover:text-yellow-900">
                <div>About Us</div>
              </a>
              <a href="#footer-section2" className="text-current no-underline hover:text-yellow-900">
                <div>Our Services</div>
              </a>
              <a href="#footer-section3" className="text-current no-underline hover:text-yellow-900">
                <div>Get Registered</div>
              </a>
              <a href="#footer-section4" className="text-current no-underline hover:text-yellow-900">
                <div>Instructions & Guidelines</div>
              </a>
              <a href="#footer-section5" className="text-current no-underline hover:text-yellow-900">
                <div>Contact Us</div>
              </a>
            </div>

            <div className='flex flex-col gap-2 text-base lg:gp-3 ml-28 lg:text-base'>
              <a href="#footer-section1" className="text-current no-underline hover:text-yellow-900">
                <div>Our Alliance Partner</div>
              </a>
              <a href="#footer-section2" className="text-current no-underline hover:text-yellow-900">
                <div>Get Brochures</div>
              </a>
              <a href="#footer-section3" className="text-current no-underline hover:text-yellow-900">
                <div>Articles</div>
              </a>
              <a href="#footer-section4" className="text-current no-underline hover:text-yellow-900">
                <div>Get Vouchers</div>
              </a>
              <a href="#footer-section5" className="text-current no-underline hover:text-yellow-900">
                <div>Referrer Program</div>
              </a>
            </div>
          </div>
        </div>

        {/* Contact */}
        <div className='flex flex-col items-center justify-center col-span-1 h-[300px]'>
          <div className='flex flex-col justify-center '>
            <div className='flex flex-row pt-4 pb-3 lg:pb-8'>
              <div className='flex flex-col justify-center pr-3'>
                <LuPhone className='w-8 h-8 text-[#002252] lg:w-12 lg:h-12' />
              </div>
              <div className='flex flex-col'>
                <div className='font-bold text-[#002252] sm:text-lg lg:text-sm'>Call us:</div>
                <div className='text-xl font-bold text-[#002252] sm:text-4xl lg:text-3xl'>+61 400 673 184</div>
              </div>
            </div>
          </div>
          <div className='w-[200px] lg:w-[180px] hover:bg-black rounded-xl'>
            <button className='w-full py-3 font-bold text-white text-sm lg:text-base rounded-lg bg-[#002252] hover:bg-[#e9ca41]'>LEARN MORE</button>
          </div>
        </div>
      </div>
      {/* Gradient bar */}
      <div className='w-full h-1 mt-5 bg-[#002252] border-2 border-[#002252]'></div>
      {/* CopyWrite Slogan */}
      <div className='flex flex-row justify-center w-11/12 mt-4 items center'>
        <div className='flex flex-col w-full lg:flex-row lg:justify-between'>
          {/* <div className='flex flex-col items-center justify-center'>
            <div className='flex flex-row'>
              <div class="text-base font-semibold mr-5">
                <span class="copywriter-icon text-base">&#169;</span> ProMendor
              </div>
              <div className='font-base'>All Right Reserved | Privacy Policy</div>
            </div>
          </div> */}

          {/* social media icons */}
          {/* <div className='flex flex-row justify-center w-full mt-3 mb-5'>
            <img src='facebook.svg' alt='' className='mr-4'/>
            <img src='x-twitter.svg' alt='' className='mr-4' />
            <img src='youtube.svg' alt='' className='mr-4' />
            <img src='instagram-filled.svg' alt='' className='mr-4' />
            <img src='pinterest.svg' alt='' className='mr-4' />
          </div> */}

          <div className='flex flex-col items-center justify-between w-full gap-2 mb-5 md:flex-row'>
            <div className='flex flex-col items-center w-full md:flex-row'>
              <div class="lg:text-base text-sm font-semibold mr-5">
                <span class="copywriter-icon lg:text-base text-sm">&#169;</span> Promender
              </div>
              <div className='text-sm lg:text-base'>All Right Reserved | Privacy Policy</div>
            </div>
            <div className='flex flex-row'>
              <img src='facebook.svg' alt='' className='mr-4' />
              <img src='x-twitter.svg' alt='' className='mr-4' />
              <img src='youtube.svg' alt='' className='mr-4' />
              <img src='instagram-filled.svg' alt='' className='mr-4' />
              <img src='pinterest.svg' alt='' className='mr-4' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
