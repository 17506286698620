import React, { useState, useEffect } from 'react';
import PageHeader from '../../components/header/PageHeader';
import NavBar from '../../components/appBar/AppBar';
import CardSlider from '../../components/cardSlider/CardSlider';
import Footer from '../../components/footer/Footer';
import NewNavBar from '../../components/appBar/NewNavBar';


const AboutUs = () => {

  const [experienceCount, setExperienceCount] = useState(0);
  const [reviewsCount, setReviewsCount] = useState(0);
  const [customersCount, setCustomersCount] = useState(0);
  const [accuracyRate, setAccuracyRate] = useState(0);
  const [isCountdownVisible, setIsCountdownVisible] = useState(false); // State to track countdown visibility

  useEffect(() => {
    const startSlowCountdownAnimation = () => {
      const targetExperience = 10;
      const targetReviews = 500;
      const targetCustomers = 800;
      const targetAccuracy = 99;

      const experienceIncrement = targetExperience / 100;
      const reviewsIncrement = targetReviews / 100;
      const customersIncrement = targetCustomers / 100;
      const accuracyIncrement = targetAccuracy / 100;

      let i = 0;
      const interval = setInterval(() => {
        if (i <= 100) {
          setExperienceCount(Math.floor(i * experienceIncrement));
          setReviewsCount(Math.floor(i * reviewsIncrement));
          setCustomersCount(Math.floor(i * customersIncrement));
          setAccuracyRate(Math.floor(i * accuracyIncrement));
          i++;
        } else {
          clearInterval(interval);
        }
      }, 50);
    };

    // Trigger animation when countdown section is visible
    if (isCountdownVisible) {
      startSlowCountdownAnimation();
    }
  }, [isCountdownVisible]);


  return (
    <div>
      <NewNavBar activePage="About us" />
      {/* Start the banner */}
      <div className="relative w-full bg-black h-100px">
        <div className='w-full h-[250px] lg:h-[450px] sm:h-[400px] bg-gradient-to-r from-[#FCD419] via-[#FCD419] to-[#ffffff]'></div>
        {/* <img src="aboutImage.jpg" alt="" className="object-cover w-full h-[250px] lg:h-[450px] sm:h-[400px]" /> */}
        <div className='absolute inset-0 flex items-center justify-center mt-3'>
          <div className="w-[1061px] h-full flex items-center justify-center">
            <div className='relative top-0 left-0 justify-start w-[200px] sm:w-[250px] lg:w-[1061px] h-full text-xl font-bold text-black'>
              <div className='border-r-2 border-b-2 sm:w-[200px] lg:w-[200px] sm:mt-16 sm:ml-10 border-black'>Hire a Technician</div>
            </div>
          </div>
        </div>
        <div className='absolute inset-0 flex flex-col items-center justify-center text-3xl font-black text-center text-black sm:text-5xl lg:text-5xl'>
          <div className=''>Book Now Pay Later with Afterpay</div>
        </div>
      </div>

      {/* About Topic */}
      <div className='flex flex-col items-center justify-center w-full gap-1 mt-16 mb-4 sm:gap-2 sm:mb-0'>
        <div className='text-2xl font-bold text-[#002252] sm:text-4xl'>About <span className='text-[#FCCF00]'>us</span></div>
        <div className='flex flex-row h-1 sm:h-2 w-[100px] sm:w-[150px]'>
          <div className='w-3/4 h-full bg-[#FCCF00]'></div>
          <div className='w-1/4 h-full bg-[#002252]'></div>
        </div>
      </div>

      {/* Content start */}
      <div className='flex flex-col flex-1 px-2 mb-10 sm:mx-5 sm:mt-12 lg:px-10 lg:hidden'>
        <div className='flex flex-row h-[770px] sm:h-[1000px] lg:h-[500px] w-full'>
          <div className='grid grid-cols-1 gap-5 lg:grid-cols-2'>
            <img src='AboutPic1.svg' alt='' className='flex object-cover' />
            <div className='flex flex-col mt-0 sm:mt-6'>
              <div className='text-3xl font-bold text-center sm:text-6xl'>Who We Are?</div>
              <div className='mt-2 text-lg font-semibold text-center sm:text-justify sm:text-xl lg:text-2xl sm:pt-10 lg:mt-5'>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</div>
              <div className='mt-2 text-sm text-center sm:text-lg sm:text-justify sm:pt-5'>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing <br /><br />Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</div>
            </div>
          </div>
        </div>
        <div className='mt-10 text-sm text-center md:mt-0 sm:text-lg sm:text-justify'>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</div>
      </div>

      <div className='flex-col flex-1 hidden px-2 mb-10 sm:mx-5 sm:mt-12 lg:px-10 lg:flex'>
        <div className='flex flex-row h-[770px] sm:h-[1000px] lg:h-[370px] xl:h-[400px] 2xl:h-[450px] w-full'>
          <div className='grid grid-cols-1 gap-5 lg:grid-cols-2'>
            <div className='w-full h-full ml-32'>
              <img src='AboutPic1.svg' alt='' className='w-[500px] h-[500px] ' />
            </div>
            <div className='flex flex-col mt-0 sm:mt-6'>
              <div className='text-3xl font-bold lg:text-4xl xl:text-3xl 2xl:text-5xl 2xl:pt-5'>Who We Are?</div>
              {/* <div className='mt-2 text-lg font-semibold xl:text-justify sm:text-xl lg:text-xl lg:pt-1 lg:mt-2 xl:text-2xl'>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</div> */}
              <div className='mt-2 text-sm 2xl:text-lg sm:text-justify sm:pt-2 lg:text-lg'>Promender is dedicated to delivering seamless support to property managers and landlords alike, drawing from a wealth of experience in real estate and a profound understanding of the industry's intricate administration and management. We're committed to alleviating the burdens faced by agents, especially amidst the implementation of new safety check regulations.</div>
              <div className='mt-2 text-sm 2xl:text-lg sm:text-justify sm:pt-2 lg:text-lg'>Our intuitive platform simplifies all administrative and compliance tasks for you and your landlords, requiring just a few clicks to accomplish. This efficiency sets us apart, making the entire process notably smoother than alternative solutions. What makes our system truly stand out is that it's fully automated, ensuring a time-efficient and professional service at every step.</div>
            </div>
          </div>
        </div>

      </div>


      {/* Content Image 1 */}
      <div className='grid w-full grid-cols-1 pt-12 lg:grid-cols-2'>
        <div className='flex flex-col items-center sm:justify-center lg:pl-10'>
          <div className='flex flex-row items-center pb-16 lg:pb-4 sm:h-32'>
            <div className='text-3xl font-bold sm:text-5xl lg:text-4xl xl:text-3xl 2xl:text-5xl'>Our Objective</div>
            <img src='AboutIcon.svg' alt='' width={120} height={45} className='hidden ml-5 sm:flex lg:flex xl:hidden' />
            <img src='AboutIcon.svg' alt='' width={150} height={45} className='hidden ml-5 sm:flex lg:hidden xl:flex' />
            <img src='AboutIcon.svg' alt='' width={80} height={25} className='ml-2 sm:hidden' />
          </div>
          <div className='mb-4 lg:hidden'>
            <img src='AboutPic2.svg' alt='' className='md:object-cover md:w-full w-[300px] h-[300px]' />
          </div>
          <div className='px-2 text-sm text-center sm:text-lg lg:text-justify lg:mr-20 lg:px-0 sm:mx-2 lg:hidden'>Our objective is clear: to offer a dependable, user-friendly, and trusted service that prioritizes transparency and value for money. As we continue to evolve our support portal, we eagerly anticipate providing our clients with a personalized and hassle-free experience, ensuring they engage with experts who comprehend their needs and can promptly assist them."</div>
          <div className='hidden px-2 text-sm text-justify sm:text-lg lg:mr-20 lg:px-0 sm:mx-2 lg:pb-10 lg:flex 2xl:text-lg'>Our objective is clear: to offer a dependable, user-friendly, and trusted service that prioritizes transparency and value for money. As we continue to evolve our support portal, we eagerly anticipate providing our clients with a personalized and hassle-free experience, ensuring they engage with experts who comprehend their needs and can promptly assist them."</div>
        </div>
        <div className='hidden mr-10 lg:flex'>
          <img src='AboutPic2.svg' alt='' className='w-[500px] h-[500px]' />
        </div>
      </div>

      <div className='grid w-full grid-cols-1 mt-10 lg:mt-0 lg:grid-cols-2'>
        <div className='hidden ml-20 lg:flex'>
          <img src='AboutPic3.svg' alt='' className='w-[500px] h-[500px]' />
        </div>
        <div className='flex flex-col items-center justify-center lg:pl-20 lg:pt-5'>
          <div className='flex flex-row items-center h-32 pb-16 lg:pb-4'>
            <div className='text-3xl font-bold sm:text-5xl lg:text-4xl xl:text-3xl 2xl:text-5xl'>Our Vision</div>
            <img src='AboutIcon.svg' alt='' width={150} height={45} className='hidden ml-5 sm:flex lg:hidden xl:flex' />
            <img src='AboutIcon.svg' alt='' width={80} height={25} className='ml-2 sm:hidden' />
            <img src='AboutIcon.svg' alt='' width={120} height={45} className='hidden ml-5 sm:flex lg:flex xl:hidden' />
          </div>
          <div className='lg:hidden'>
            <img src='AboutPic3.svg' alt='' className='object-cover w-full' />
          </div>
          <div className='px-3 mt-8 text-sm text-center sm:px-0 sm:text-lg sm:text-justify lg:mr-20 lg:hidden'>At Promender, we're constantly refining and streamlining the client experience. Our promise is to continually seek out ways to enhance, simplify, and improve our services. We take pride in our reputation for reliability, trustworthiness, and efficiency and are dedicated to providing our Property Manager Partners with an effortlessly straightforward service.</div>
          <div className='hidden px-2 text-sm text-justify sm:text-lg lg:mr-20 lg:px-0 sm:mx-2 lg:pb-10 lg:flex 2xl:text-lg'>At Promender, we're constantly refining and streamlining the client experience. Our promise is to continually seek out ways to enhance, simplify, and improve our services. We take pride in our reputation for reliability, trustworthiness, and efficiency and are dedicated to providing our Property Manager Partners with an effortlessly straightforward service.</div>
          <div className='hidden px-2 text-sm text-justify sm:text-lg lg:mr-20 lg:px-0 sm:mx-2 lg:pb-10 lg:flex 2xl:text-lg'>
            Recognizing the pain points prevalent in the industry—dishonesty, unreliability, and a lack of transparency—we're proactive in addressing these issues before they impact our clients. From tardy tradesmen to ambiguous arrival windows for tenants, we aim to preemptively resolve these concerns.
          </div>
        </div>

      </div>

      {/* Projects count */}
      <div
        className='w-full h-[650px] sm:h-[220px] bg-[#002252] mt-32 mb-20'
        ref={(node) => {
          // Detect when the countdown section is in viewport
          if (node) {
            const observer = new IntersectionObserver(
              (entries) => {
                entries.forEach((entry) => {
                  if (entry.isIntersecting) {
                    setIsCountdownVisible(true);
                  }
                });
              },
              { threshold: 0.5 } // Adjust threshold as needed
            );
            observer.observe(node);
          }
        }}
      >
        <div className='flex flex-col items-center justify-between h-full pt-5 mx-32 sm:mx-0 sm:pt-0 sm:flex-row'>
          <div className='flex flex-col items-center justify-center w-full'>
            <div className='mb-2 text-5xl font-bold text-white sm:text-4xl lg:text-6xl'>{experienceCount}+</div>
            <div className='text-xl text-center text-white sm:text-base xl:text-xl'>Years Experience</div>
          </div>
          <div className='flex w-1 bg-white h-1/5 sm:h-1/2'></div>

          <div className='flex flex-col items-center justify-center w-full'>
            <div className='mb-2 text-5xl font-bold text-white sm:text-4xl lg:text-6xl'>{reviewsCount}+</div>
            <div className='text-xl text-center text-white sm:text-base xl:text-xl'>Positive Reviews</div>
          </div>
          <div className='flex w-1 bg-white h-1/5 sm:h-1/2'></div>

          <div className='flex flex-col items-center justify-center w-full'>
            <div className='mb-2 text-5xl font-bold text-white sm:text-4xl lg:text-6xl'>{customersCount}+</div>
            <div className='text-xl text-center text-white sm:text-base xl:text-xl'>Trusted Customers</div>
          </div>
          <div className='flex w-1 bg-white h-1/5 sm:h-1/2'></div>

          <div className='flex flex-col items-center justify-center w-full pb-0 sm:pb-0'>
            <div className='mb-2 text-5xl font-bold text-white sm:text-4xl lg:text-6xl'>{accuracyRate}%</div>
            <div className='text-xl text-center text-white sm:text-base xl:text-lg'>Accuracy Rate</div>
          </div>
        </div>
      </div>

      <CardSlider />
      <Footer />

    </div>
  );
};

export default AboutUs;
