import React, { useState, useEffect } from "react";
import {
  request,
  getAuthToken,
  setAuthHeader,
  axiosInstance1,
  axiosInstance2,
} from "../../config/axiosConfig"; // Assuming you have a request function for API calls
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import TechnicianViewDetailsPopup from "../../components/dashboard/TechnicianViewDetails";
import StripeCheckout from 'react-stripe-checkout';

function TechnicianDashboardPage() {
  const [showPopup, setShowPopup] = useState(false);
  const [isOngoingInquiriesLoading, setIsOngoingInquiriesLoading] =
    useState(false);
  const [ongoingCustomerInquiries, setOngoingCustomerInquiries] = useState([]);
  const [isPendingInquiriesLoading, setIsPendingInquiriesLoading] =
    useState(false);
  const [pendingCustomerInquiries, setPendingCustomerInquiries] = useState([]);
  const [technician, setTechnician] = useState([]);
  const [commitment, setCommitment] = useState("");
  const [cardId, setCardId] = useState("");
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [currentStatus, setCurrentStatus] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchCustomerInquiries();
    fetchTechnician();
  }, []);

  const [totalInquiries, setTotalInquiries] = useState(0);
  const [pendingInquiries, setPendingInquiries] = useState(0);
  const [ongoingInquiries, setOngoingInquiries] = useState(0);
  const [completeInquiries, setCompleteInquiries] = useState(0);
  const [payment, setPayment] = useState(0.00);

  const userId = localStorage.getItem("userid");
  const user = localStorage.getItem("firstName");
  const welcomeUser = user.charAt(0).toUpperCase() + user.slice(1);

  const handleChecklistClick = (serviceType, inquiryId) => {
    localStorage.setItem("serviceType", serviceType);
    localStorage.setItem("InquiryId", inquiryId);
  };

  const MySwal = withReactContent(Swal);

  const navigate = useNavigate();

  //getTechnicianData
  const fetchTechnician = async () => {
    try {
      // console.log("Requesting technician data ...");
      const response = await request(
        axiosInstance1,
        "GET",
        `/getTechnicianDetailsByUserId/${userId}`
      );
      setTechnician(response.data);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching technicians:", error);
    }
  };

  // console.log("status", technician);

  //getDashboardData  ----> card data
  useEffect(() => {
    // console.log("user Id :", userId);
    fetchData();
  }, [userId]); // re-run effect when userId changes

  const fetchData = async () => {
    try {
      const totInqResponse = await request(
        axiosInstance2,
        "GET",
        "/getTotalTechnicianInquiries",
        null,
        { userid: userId }
      );
      console.log("Total Inquaries :", totInqResponse.data);
      setTotalInquiries(totInqResponse.data);

      const penbInqResponse = await request(
        axiosInstance2,
        "GET",
        "/getTotalPendingTechnicianInquiries",
        null,
        { userid: userId }
      );
      // console.log("Pending Inquaries :", penbInqResponse.data);
      setPendingInquiries(penbInqResponse.data);

      const ongoInqResponse = await request(
        axiosInstance2,
        "GET",
        "/getTotalOngoingTechnicianInquiries",
        null,
        { userid: userId }
      );
      // console.log("Ongoing Inquaries :", ongoInqResponse.data);
      setOngoingInquiries(ongoInqResponse.data);

      const comInqResponse = await request(
        axiosInstance2,
        "GET",
        "/getTotalCompleteTechnicianInquiries",
        null,
        { userid: userId }
      );
      // console.log("Completed Inquaries :", comInqResponse.data);
      setCompleteInquiries(comInqResponse.data);

      const paymentResponse = await request(
        axiosInstance2,
        "GET",
        "/getTechnicianCurrentWeekPayment",
        null,
        { userid: userId }
      );
      // console.log("Payment :", paymentResponse.data);
      setPayment(paymentResponse.data.toFixed(2));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCustomerInquiries = async () => {
    try {
      setIsOngoingInquiriesLoading(true);
      const response = await request(
        axiosInstance2,
        "GET",
        "/getTechnicianOngoingInquiries",
        null,
        { userid: userId }
      );
      setOngoingCustomerInquiries(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setIsOngoingInquiriesLoading(false);
    }

    try {
      setIsPendingInquiriesLoading(true);
      const response = await request(
        axiosInstance2,
        "GET",
        "/getTechnicianPendingInquiries",
        null,
        { userid: userId }
      );
      setPendingCustomerInquiries(response.data);
      setIsPendingInquiriesLoading(false);
      console.log("fetching customers:", pendingCustomerInquiries);
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setIsPendingInquiriesLoading(false);
    }
  };

  const openPopup = (cardId) => {
    setCardId(cardId);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const openDetailsPopup = (status, customer) => {
    setShowDetailsPopup(true);
    setCurrentStatus(status);
    setSelectedStatus(status);
    setSelectedCustomer(customer);
  };

  const closeDetailsPopup = () => {
    setCurrentStatus(null);
    setSelectedCustomer(null);
    setSelectedStatus(null);
    setShowDetailsPopup(false);
  };

  const handleClear = () => {
    setCommitment("");
  };

  const handleAccept = async (event) => {
    event.preventDefault();

    try {
      setIsSubmitting(true);
      setAuthHeader(getAuthToken());

      const responseInq = await request(
        axiosInstance2,
        "PUT",
        `/acceptTask/${cardId}`,
        { commitment, userId }
      );
      fetchCustomerInquiries();
      closePopup();

      fetchData();

      // Show success message with SweetAlert
      MySwal.fire({
        icon: "success",
        title: "Accepted",
        text: "Task accepted successfully.",
      });
    } catch (error) {
      console.log(error);

      // Show error message with SweetAlert
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "Error accepting task.",
      });
    } finally {
      setIsSubmitting(false);
    }
    fetchCustomerInquiries();
  };

  const OrderTable = ({ title, data }) => {
    return (
      <div style={{ margin: "20px 0" }}>
        <div className="flex flex-row justify-start w-full px-4 mt-2 mb-4 text-xl font-bold text-gray-800 md:px-16 sm:text-2xl lg:text-2xl">
          {title}
        </div>

        <div style={{ overflowX: "auto" }}>
          <Table
            sx={{
              minWidth: 650,
              maxWidth: "100%",
              marginInline: 10,
              marginTop: 5,
            }}
            aria-label={`${title} table`}
          >
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Order ID
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Customer Name
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Contact Number
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Preferred Date
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Preferred Time
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Status
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((order) => (
                <TableRow key={order.orderId} style={{ cursor: "pointer" }}>
                  <TableCell align="center">
                    {order.customerInquiryId}
                  </TableCell>
                  <TableCell align="center">
                    {order.firstName} {order.lastName}
                  </TableCell>
                  <TableCell align="center">{order.contactNumber}</TableCell>
                  <TableCell align="center">{order.inquiryDate}</TableCell>
                  <TableCell align="center">{order.inquiryTime}</TableCell>
                  <TableCell align="center">
                    <div className="px-1 py-2 font-semibold bg-yellow-700 rounded-full">
                      {order.overallStatus}
                    </div>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ display: "flex", flexDirection: "row", gap: 1 }}
                  >
                    <button
                      className="px-3 py-2 text-sm font-semibold text-black border-2 border-black rounded-full"
                      onClick={() =>
                        openDetailsPopup(order.overallStatus, order)
                      }
                    >
                      View Details
                    </button>
                    {title === "Pending Orders" &&
                      order.overallStatus === "Approved" && (
                        <button
                          className="w-40 py-2 px-2.5 font-semibold text-white bg-[#002252] rounded-full"
                          onClick={() => openPopup(order.customerInquiryId)}
                        >
                          Accept Inquiry
                        </button>
                      )}
                    {title === "Ongoing Orders" &&
                      order.overallStatus === "Paid" &&
                      order.checklistStatus !== "Done" && (
                        <Link
                          to="/technicianChecklist"
                          className="w-32 py-2 ml-2 font-semibold text-white bg-[#002252] rounded-full flex items-center justify-center"
                          style={{ textDecoration: "none" }}
                          onClick={() =>
                            handleChecklistClick(
                              order.type,
                              order.customerInquiryId
                            )
                          }
                        >
                          Checklist
                        </Link>
                      )}
                    {title === "Ongoing Orders" &&
                      (order.overallStatus === "Paid" ||
                        order.overallStatus === "Completed") &&
                      order.checklistStatus === "Done" && (
                        <button
                          className="w-32 py-2 ml-2 font-semibold text-white bg-[#002252] rounded-full"
                          onClick={() =>
                            navigate(
                              `/portal/reportsPage/${order.customerInquiryId}`,
                              { state: { order } }
                            )
                          }
                        >
                          Report
                        </button>
                      )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  };

  return (
    <div div className="flex flex-col bg-gray-300">
      <div className="flex flex-col px-4 pt-10 lg:px-16 ">
        <div className="text-2xl font-bold text-gray-800 sm:text-3xl lg:text-4xl">
          Welcome back {welcomeUser}
        </div>
        <div className="text-sm sm:text-base">
          Check your last activity today
        </div>
      </div>
      <div className="flex flex-col lg:flex-row w-full h-[350px] lg:h-[200px] mt-10 lg:mt-1 lg:px-16 items-center mb-[250px] lg:mb-0">
        {/* box 1 (Pending Inquiries) */}
        <div className="flex flex-col w-full gap-1 px-4 lg:flex-row lg:px-1 lg:hidden">
          <div className="xl:w-1/2 w-full h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between items-center">
            <div className="text-lg font-semibold text-center text-white sm:text-lg lg:text-xl">
              Pending Inquiries
            </div>
            <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-6xl">
              {pendingInquiries}
            </div>
          </div>
        </div>

        {/* box 2 (Ongoing Inquiries) */}
        <div className="flex flex-col w-full gap-1 px-4 mt-2 lg:flex-row lg:px-1 lg:hidden">
          <div className="xl:w-1/2 w-full h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between items-center">
            <div className="text-lg font-semibold text-center text-white sm:text-lg lg:text-xl">
              Ongoing Inquiries
            </div>
            <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-6xl">
              {ongoingInquiries}
            </div>
          </div>
        </div>

        {/* box 3 (Completed Inquiries) */}
        <div className="flex flex-col w-full gap-1 px-4 mt-2 lg:flex-row lg:px-1 lg:hidden">
          <div className="xl:w-1/2 w-full h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between items-center">
            <div className="text-lg font-semibold text-center text-white sm:text-lg lg:text-xl">
              Completed Inquiries
            </div>
            <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-6xl">
              {completeInquiries}
            </div>
          </div>
        </div>

        {/* Large Screen Layout */}
        <div className="flex-col hidden w-full gap-1 px-1 lg:flex-row lg:flex">
          <div className="lg:w-1/3 w-full h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between items-center">
            <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-lg">
              Pending Inquiries
            </div>
            <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
              {pendingInquiries}
            </div>
          </div>

          <div className="lg:w-1/3 w-full h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between items-center">
            <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-lg">
              Ongoing Inquiries
            </div>
            <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
              {ongoingInquiries}
            </div>
          </div>

          <div className="lg:w-1/3 w-full h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between items-center">
            <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-lg">
              Completed Inquiries
            </div>
            <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
              {completeInquiries}
            </div>
          </div>
        </div>
      </div>

      {/* Current Payment for Week */}
      <div className="lg:w-full mt-2 flex flex-col lg:flex-row lg:px-16 items-center">
        <div className="lg:w-1/3 w-full h-[140px] bg-yellow-700 rounded-xl py-4 flex flex-col justify-between items-center mb-10">
          <div className="text-base font-semibold text-center text-gray-800 sm:text-xl lg:text-lg">
            Current Payment for Week
          </div>
          <div className="mt-2 text-4xl font-bold text-gray-700 md:text-5xl lg:text-5xl">
            AUD {payment}
          </div>
        </div>
      </div>

      {/* Pending Orders Table */}
      {isPendingInquiriesLoading ? (
        <div className="flex items-center justify-center h-full">
          <CircularProgress />
        </div>
      ) : pendingCustomerInquiries.length === 0 ? (
        <div className="flex items-left h-full">
          <p className="text-gray-500 px-4 text-lg font-semibold">
            You do not have pending inquiries.
          </p>
        </div>
      ) : (
        <OrderTable title="Pending Orders" data={pendingCustomerInquiries} />
      )}

      {/* Ongoing Orders Table */}
      {isOngoingInquiriesLoading ? (
        <div className="flex items-center justify-center h-full">
          <CircularProgress />
        </div>
      ) : ongoingCustomerInquiries.length === 0 ? (
        <div className="flex items-left h-full">
          <p className="text-gray-500 px-4 text-lg font-semibold">
            You do not have ongoing inquiries.
          </p>
        </div>
      ) : (
        <OrderTable title="Ongoing Orders" data={ongoingCustomerInquiries} />
      )}

      {showDetailsPopup && (
        <TechnicianViewDetailsPopup
          closeDetailsPopup={closeDetailsPopup}
          overallStatus={currentStatus}
          selectedCustomer={selectedCustomer}
        />
      )}

      {/* Popup */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="p-6 bg-white rounded-lg w-[350px] ml-[56px] h-[330px] flex flex-col lg:w-[500px] lg:h-[330px]">
            <div className="flex justify-end">
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={closePopup}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div>
              <Typography
                variant="h6"
                sx={{ color: "#002252", fontWeight: "bold" }}
              >
                Accepting Task
              </Typography>
              <div className="w-full h-full mt-6">
                <TextField
                  id="taskDescription"
                  name="taskDescription"
                  label="Enter Task Remarks"
                  multiline
                  rows={4}
                  value={commitment}
                  onChange={(e) => setCommitment(e.target.value)}
                  fullWidth
                />
              </div>
            </div>
            <div>
              <Grid container justifyContent="flex-end" sx={{ marginTop: 4 }}>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 5,
                    background: " #002252",
                    marginRight: 4,
                    fontWeight: "bold",
                  }}
                  onClick={handleClear}
                >
                  Clear
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    borderRadius: 5,
                    background: " #002252",
                    marginRight: 4,
                    fontWeight: "bold",
                  }}
                  onClick={handleAccept}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Please wait..." : "Accept"}
                </Button>
              </Grid>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default TechnicianDashboardPage;
