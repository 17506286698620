import "./App.css";
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import AppContent from "./pages/content/AppContent";
import Home from "./pages/home/Home";
import Aboutus from "./pages/home/Aboutus";
import Contactus from "./pages/home/Contactus";
import OurServices from "./pages/home/OurServices";
import ElectricalServices from './pages/home/ElectricalServices';
import GasServices from "./pages/home/GasServices";
import MainReg from "./pages/Registration/MainReg";
import AdminDashboard from "./pages/dashboard/Dashboard";
import TechnicianReg from "./pages/Registration/TechnicianReg";
import CustomerReg from "./pages/Registration/CustomerReg";
import TechnicianLogin from "./pages/login/TechnicianLogin";
import CustomerLogin from "./pages/login/CustomerLogin";
import AdminLogin from "./pages/login/AdminLogin";
import ForgotPassword from "./pages/login/ForgotPassword";
import LoginForm from "./pages/content/AppContent";
import TechnicianList from  "./pages/userLists/TechnicianList";
import CustomerList from  "./pages/userLists/CustomerList";
import TechnicianPaymentList from  "./pages/userLists/TechnicianPaymentList";
import CompletedPaymentList from "./pages/userLists/CompletedPaymentList";
import Quote from "./pages/home/Quote";
import Dashboard from "./pages/dashboard/Dashboard";
import CustomerProfile from "./pages/profile/CustomerProfile";
import TechnicianProfile from "./pages/profile/TechnicianProfile";
import RegWithInquiry from "./pages/Registration/RegWithInquiry";
import CustomerInquiry from "./pages/CustInquiry/Inquiry";
import TechnicianDashboardPage from "./pages/technicianDashboard/TechnicianDashboardPage";
import CustomerDashboardPage from "./pages/CustomerDashboard/CustomerDashboardPage";
import AdminDashboardContent from "./pages/adminDashboard/AdminDasboardContent";
import PaymentPage from "./pages/PaymentPage/Payment";
import Student from "./Student";
import TechnicianChecklist from "./pages/checklists/TechnicianChecklist";
import AdminProfile from "./pages/profile/AdminProfile";
import ResetPassword from "./pages/login/ResetPassword";
import CreatePassword from "./pages/login/CreatePassword";
import ReportsPage from "./pages/Reports/ReportsPage";
import Reports from "./pages/dashboard/Reports";
import MailVerification from "./pages/Registration/MailVerification";
import CustomerCompletedInquiries from "./pages/CustomerDashboard/CustomerCompletedInquiries";
import TechnicianCompletedInquiries from "./pages/technicianDashboard/TechnicianCompletedInquiries";
import AdminCompletedInquiries from "./pages/adminDashboard/AdminCompletedInquiries";


function App() {
  return (

    <Router>
      <Routes>
      <Route path="test" element={<Student />}></Route>
        <Route path="" element={<Home />}></Route>
        <Route path="/aboutus" element={<Aboutus />}></Route>
        <Route path="/contactus" element={<Contactus />}></Route>
        <Route path="/ourServices" element={<OurServices />}></Route>
        <Route
          path="/electricalServices"
          element={<ElectricalServices />}
        ></Route>
        <Route path="/gasServices" element={<GasServices />}></Route>
        <Route path="/regwithinquiry" element={<RegWithInquiry />}></Route>
        {/* <Route path="/custominq" element={<RegWithInquiry />}></Route> */}
        <Route path="/regWithInq" element={<RegWithInquiry />}></Route>
        <Route path="technicianProfile" element={<TechnicianProfile />} />
        <Route path="/adminLoginReg" element={<AppContent />}></Route>
        <Route path="/loginForm" element={<LoginForm />}></Route>
        <Route path="/technicianReg" element={<TechnicianReg />}></Route>
        <Route path="/customerReg" element={<CustomerReg />}></Route>
        <Route path="/technicianLogin" element={<TechnicianLogin />}></Route>
        <Route path="/customerLogin" element={<CustomerLogin />}></Route>
        <Route path="/adminLogin" element={<AdminLogin />}></Route>
        <Route path="/forgotPassword" element={<ForgotPassword />}></Route>
        <Route path="/regmain" element={<MainReg />}></Route>
        <Route path="/portal" element={<AdminDashboard />}></Route>
        <Route path="/technicianList" element={<TechnicianList />}></Route>
        <Route path="/customerList" element={<CustomerList />}></Route>
        <Route path="/quote" element={<Quote />}></Route>
        <Route path="portal" element={<Dashboard />}>
          <Route path="customerList" element={<CustomerList />} />
          <Route path="technicianList" element={<TechnicianList />} />
          <Route path="technicianPaymentList" element={<TechnicianPaymentList />} />
          <Route path="completedPaymentList" element={<CompletedPaymentList />} />
          <Route path="reports" element={<Reports />} />
          <Route path="customerinq" element={<CustomerInquiry />}></Route>
          <Route path="customerProfile" element={<CustomerProfile/>}/>
          <Route path="technicianProfile" element={<TechnicianProfile/>}/>
          <Route path="adminProfile" element={<AdminProfile/>}/>
          <Route path="technicianDashboard" element={<TechnicianDashboardPage/>}/>
          <Route path="adminDashboard" element={<AdminDashboardContent/>}/>
          <Route path="customerDashboard" element={<CustomerDashboardPage/>}/>
          <Route path="reportsPage/:inquiryId" element={<ReportsPage/>}/>
          <Route path="customerCompletedInq" element={<CustomerCompletedInquiries/>}/>
          <Route path="technicianCompletedInq" element={<TechnicianCompletedInquiries/>}/>
          <Route path="adminCompletedInq" element={<AdminCompletedInquiries/>}/>
        </Route>
        <Route path="/payment" element={<PaymentPage />}></Route>
        <Route path="/technicianChecklist" element={<TechnicianChecklist/>}/>
        <Route path="/resetPassword" element={<ResetPassword/>}/>
        <Route path="/createPassword" element={<CreatePassword/>}/>
        <Route path="/verify" element={<MailVerification/>}/>
      </Routes>
    </Router>
 
    
  );
}


export default App;

