import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/header/PageHeader";
import NavBar from "../../components/appBar/AppBar";
import { Button } from "@mui/material";
import Slider from "../../components/slider/Slider";
import Footer from "../../components/footer/Footer";
import { TiTick } from "react-icons/ti";
import { request, axiosInstance1 } from "../../config/axiosConfig"; // Make sure to install axios
import DownloadButton from "../../components/buttons/downloadButton";
import NewNavBar from "../../components/appBar/NewNavBar";
import image from "../../image/homeback.png"

const Home = () => {
  const navigate = useNavigate();
  const [selectedTypes, setSelectedTypes] = useState({});
  const [prices, setPrices] = useState({});
  const [servicePrices, setServicePrices] = useState([]);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    fetchServiceData();
  }, []);

  const fetchServiceData = async () => {
    try {
      console.log("Requesting service data ...");
      const response = await request(
        axiosInstance1,
        "GET",
        `/getserviceTypesWithPrices`
      );
      const data = response.data;
      setServicePrices(data);
      console.log(response.data);

      const initialSelectedTypes = {};
      const initialPrices = {};
      data.forEach((service) => {
        initialSelectedTypes[service.serviceType] = false;
        initialPrices[service.serviceType] = {
          name: service.serviceType,
          price: service.price,
          selected: false,
        };
      });
      setSelectedTypes(initialSelectedTypes);
      setPrices(initialPrices);
    } catch (error) {
      console.error("Error fetching service data:", error);
      localStorage.clear();
      window.location.reload();
    }
  };

  console.log("Service data", servicePrices);

  const handleButtonClick = (type) => {
    setSelectedTypes((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
    setPrices((prevPrices) => ({
      ...prevPrices,
      [type]: {
        ...prevPrices[type],
        selected: !prevPrices[type].selected,
      },
    }));
  };

  // const getPrices = () => {
  //   const prices = {};
  //   servicePrices.forEach((service) => {
  //     prices[service.serviceType] = {
  //       name: service.serviceType,
  //       price: service.price,
  //       selected: selectedTypes[service.serviceType] || false,
  //     };
  //   });
  //   return prices;
  // };

  const getTotalPrice = () => {
    return Object.values(prices)
      .filter((price) => price.selected)
      .reduce((total, price) => total + price.price, 0);
  };

  const handleProceedClick = () => {
    const selectedItems = Object.keys(selectedTypes).filter(
      (type) => selectedTypes[type]
    );

    const totalPrice = getTotalPrice(); 

    if (selectedItems.length === 0) {
      setShowWarning(true);
    } else {
      navigate("/regwithinquiry", { state: { selectedItems, totalPrice } });
    }
  };

  return (

    
    
      
    <div className="bg-white">

      
      
      <NewNavBar activePage="home" />

      <div className="flex flex-col w-full px-2 md:px-10 md:h-[750px]">
        <div className="flex flex-col border-2 h-[800px] md:h-[1400px]"  
          style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        >
          
        <div className="flex flex-col justify-center items-center bg-[#002252] w-full h-[100px] rounded-b-2xl shadow-md shadow-[#FCCF00]">
            <div className="text-lg font-black text-center text-gray-200 md:w-11/12 md:text-xl lg:text-2xl">
              Comprehensive Gas, Electrical & Smoke Alarm Check at your
              convenience at guaranteed best price.
            </div>
          </div>

          <div className="flex flex-col">
            <div className="pt-10 text-2xl font-bold text-center text-[#ffffff]">Please Select Required Services</div>
            <div className="flex flex-row justify-center w-full gap-2 mt-10 lg:gap-6 md:mt-10 md:gap-6">
              {servicePrices.map((service) => (
                <button
                  key={service.serviceType}
                  className={`lg:w-[250px] w-[110px] h-[60px] lg:h-[90px] border-2 lg:rounded-2xl justify-center lg:pl-4 text-gray-800 flex flex-col bg-gradient-to-r from-[#E0D5A4] to-[#B1B7B7] ${
                    selectedTypes[service.serviceType]
                      ? "border-indigo-900"
                      : "border-gray-300"
                  } ${
                    selectedTypes[service.serviceType]
                    ? "text-indigo-900"
                      : "text-gray-800"
                  }`}
                  style={{
                    // border: "2px solid transparent",
                    borderRadius: "15px",
                  }}
                  onClick={() => handleButtonClick(service.serviceType)}
                >
                
                  <div className="hidden text-sm font-bold md:text-xl lg:flex">
                    {service.serviceType}
                  </div>

                  <div className="flex flex-row justify-center w-full text-sm font-bold text-center md:text-xl lg:hidden">
                    {service.serviceType}
                  </div>

                  <div className="flex-row items-center hidden lg:flex">
                  <div
                    className={`lg:flex flex-col hidden items-center justify-center w-4 h-4 lg:w-6 lg:h-6 mt-2 md:w-8 md:h-8 md:mt-16  rounded-full  ${
                      selectedTypes[service.serviceType]
                        ? "bg-indigo-900"
                        : "bg-white"
                    }`}
                  >
                    {/* <TiTick
                      className={`${
                        selectedTypes[service.serviceType] ? "block" : "hidden"
                      } ${
                        selectedTypes[service.serviceType]
                          ? "text-[#002945]"
                          : "text-white"
                      }`}
                    /> */}
                  </div>
                    <div className="flex flex-row text-xs lg:ml-2 lg:text-sm"><div className="mr-1 font-semibold">Service Cost: $</div><div className="font-semibold">{service.price}</div></div>
                  </div>
               
                </button>
              ))}
            </div>
          </div>
          <div className="flex flex-col items-center w-full pt-[50px] h-[250px]">
            <div className="flex flex-col items-center justify-center w-full h-full px-2 lg:px-20 lg:w-2/3 rounded-xl">
              <div className="flex flex-col lg:flex-row w-full sm:h-[300px] h-[200px] lg:h-[200px] sm:[400px] justify-center gap-4">
                <div className="flex-row items-center justify-center hidden w-full h-full bg-[#f4ebaf] lg:flex rounded-xl">
                  <div className="flex flex-col">
                    <div className="mb-2 font-bold text-black lg:text-xl">
                      Selected Services
                    </div>
                    {Object.keys(prices).map(
                      (type) =>
                        prices[type].selected && (
                          <div
                            key={type}
                            className="flex flex-row justify-between"
                          >
                            <span className="text-sm font-semibold lg:text-lg">
                              {prices[type].name}
                            </span>
                            <div className="flex flex-row gap-1">
                              <span className="ml-8 text-sm lg:text-lg">
                                ${prices[type].price}
                              </span>
                            </div>
                            
                          </div>
                        )
                    )}
                  </div>
                </div>
                <div className="hidden lg:flex flex-row w-full h-[200px] justify-center items-center">
                  <div className="w-full h-full bg-[#f4ebaf] rounded-lg flex flex-col justify-center items-center">
                  <div className="text-sm font-semibold text-black">
                    Total Cost
                  </div>

                    <div className="flex flex-row items-center gap-1">
                      <span className="text-xl font-semibold text-black">
                        $
                      </span>
                      <span className="text-3xl font-bold text-black">
                        {getTotalPrice()}
                      </span>
                  </div>
                    
                  </div>
                </div>

                <div className="w-full px-4 bg-[#f4ebaf] rounded-lg lg:hidden sm:pt-4 h-1/2">
                  <div className="flex flex-col">
                    <div className="mb-2 font-bold text-center text-gray-800 lg:text-xl sm:text-lg">
                      Selected Services
                    </div>
                    {Object.keys(prices).map(
                      (type) =>
                        prices[type].selected && (
                          <div
                            key={type}
                            className="flex flex-row justify-between"
                          >
                            <span className="text-sm font-semibold text-gray-800 lg:text-lg sm:text-base">
                              {prices[type].name}
                            </span>
                            <span className="ml-8 text-sm lg:text-lg sm:text-base">
                              ${prices[type].price}
                            </span>
                          </div>
                        )
                    )}
                  </div>
                </div>

                <div className="lg:hidden flex flex-col justify-center bg-[#f4ebaf] w-full h-1/2 rounded-lg items-center">
                  <div className="text-xs font-semibold text-gray-800 sm:text-sm">
                    Total Cost
                  </div>
                  <span className="text-3xl font-bold text-gray-800">
                    ${getTotalPrice()}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-center w-full px-2 mt-10">

            {/* <div className="flex flex-col md:w-[800px]">
              <div className="font-semibold text-black md:text-xl">
                What you will get:
              </div>
              <div className="flex flex-col md:pl-[220px] mt-4 md:gap-4 gap-2">
                {Object.keys(selectedTypes)
                  .filter((type) => selectedTypes[type])
                  .map((type) => (
                    <div key={type} className="flex flex-row items-center">
                      <label
                        htmlFor="my-checkbox"
                        className="text-gray-700 md:text-xl"
                      >
                        {prices[type]?.name || type}
                      </label>
                    </div>
                  ))}
              </div>
            </div> */}
          </div> 

          {showWarning && (
            <div className="flex flex-row justify-center w-full mt-5 text-red-600">
              Please select at least one type to proceed.
            </div>
          )}

          <div className="flex flex-row justify-center w-full">
            <Button
              onClick={handleProceedClick}
              className="flex flex-col w-[120px] h-[60px] md:w-[200px] md:h-[70px]"
            >
              <div className="bg-[#004080] hover:bg-[#ddc03e] rounded-lg w-full h-full justify-center items-center flex flex-col">
                <div className="font-bold text-white md:text-base">PROCEED</div>
              </div>
            </Button>
          </div>
        </div>
      </div>

      <Slider />

      {/* Start contend*/}
      <div className="flex flex-col px-10 mt-20 lg:mb-48">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="flex flex-col w-full h-full">
            <div className="w-full h-[450px] lg:h-[500px]">
              <img src="HomeSub2.svg" alt="" className="w-full h-full" />
            </div>
            <div className=" lg:flex">
              <div className="flex flex-col justify-between h-full mb-3">
                <div className="mt-4 mr-5 text-3xl font-black text-black lg:text-4xl">
                  Keeping homes safe & compliant.
                </div>
                <div className="mt-4 text-black lg:text-xl">
                  We help protect your home & investment with cost effective and
                  comprehensive gas, electric & smoke alarm safety testing.
                </div>
                <div className="mb-20 font-bold text-gray-400 lg:text-xl">
                  Plus, we will beat any price!*
                </div>
                <div>
                  <Button
                    className="flex flex-col w-[120px] h-[50px] md:w-[180px] md:h-[70px]"
                    style={{ textDecoration: "none" }}
                    onClick={() => navigate("/register")}
                    to="/register"
                  >
                    <div className="bg-[#002252] rounded-lg w-full h-full justify-center items-center flex hover:bg-[#ddc03e] flex-col">
                      <div className="font-bold text-white md:text-base">
                        Register
                      </div>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full h-full">
            <div className="hidden lg:flex">
              <div className="flex flex-col justify-between h-full mb-3">
                <div className="mt-4 mr-5 text-3xl font-black text-black lg:text-4xl">
                  Keeping homes safe & compliant.
                </div>
                <div className="text-black lg:text-xl">
                  We help protect your home & investment with cost effective and
                  comprehensive gas, electric & smoke alarm safety testing.
                </div>
                <div className="mb-20 font-bold text-gray-400 lg:text-xl">
                  Plus, we will beat any price!*
                </div>
                <div>
                  <Button
                    className="flex flex-col w-[120px] h-[50px] md:w-[180px] md:h-[70px]"
                    onClick={() => navigate("/register")}
                    to="/register"
                  >
                    <div className="bg-[#002252] rounded-lg w-full hover:bg-[#ddc03e] h-full justify-center items-center flex flex-col">
                      <div className="font-bold text-white md:text-base">
                        Register
                      </div>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
            <div className="w-full h-[450px] lg:h-[500px]">
              <img src="HomeSub1.svg" alt="" className="w-full h-full" />
            </div>

            <div className="flex lg:hidden">
              <div className="flex flex-col justify-between h-full mb-3">
                <div className="mt-4 mr-5 text-3xl font-black text-black lg:text-6xl">
                  Keeping homes safe & compliant.
                </div>
                <div className="text-black lg:text-2xl">
                  We help protect your home & investment with cost effective and
                  comprehensive gas, electric & smoke alarm safety testing.
                </div>
                <div className="mb-20 font-bold text-gray-400 lg:text-2xl">
                  Plus, we will beat any price!*
                </div>
                <div>
                  <Button
                    className="flex flex-col w-[120px] h-[50px] md:w-[200px] md:h-[60px]"
                    onClick={() => navigate("/register")}
                    to="/register"
                  >
                    <div className="bg-[#002252] rounded-lg w-full h-full justify-center items-center flex flex-col">
                      <div className="font-bold text-white md:text-xl">
                        Register
                      </div>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
